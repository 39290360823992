import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import AddCommentIcon from '@mui/icons-material/AddComment';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import TimeAgo from 'react-timeago'

const MiniPost = (props) => {
  const isVid = (fname) => {
    if (fname.indexOf('mp4') !== -1) {
      return true;
    } else if (fname.indexOf('webm') !== -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className='minipost'>
      { (props.post.image_url && !isVid(props.post.image_url))
         && <img alt='' src={props.post.image_url} style={{borderRadius: '14px', width: '56px', height: '74px'}} /> }
      { (props.post.image_url && isVid(props.post.image_url))
         && <VideocamOutlinedIcon /> }
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', marginLeft: '10px', textAlign: 'left', height: '22px'}}>
         <div style={{display: 'flex', flex: 0.8}}>
           <div>
             <TimeAgo date={props.post.created_at} /><br />
             <span style={{fontSize: '14px'}}>{props.post.location.map(l => Math.round(l * 100000) / 100000).join(', ')}</span>
           </div>
         </div>
         <div style={{display: 'flex', flex: 0.3}}>
           <ThumbUpAltIcon /><div style={{padding: '4px', fontSize: '16px'}}> {props.post.likes.length}</div>
         </div>
         <div style={{display: 'flex', flex: 0.3}}>
           <AddCommentIcon /><div style={{padding: '4px', fontSize: '16px'}}> {props.post.comments.length}</div>
         </div>
       </div>
    </div>
  );
}

export default MiniPost;
