import { MapContainer } from 'react-leaflet/MapContainer';
import { TileLayer } from 'react-leaflet/TileLayer';
import { Marker } from 'react-leaflet/Marker';
import { Popup } from 'react-leaflet/Popup';
import { renderToStaticMarkup } from 'react-dom/server';
import { divIcon } from 'leaflet';

const Map = (props) => {
  // const iconMarkup = renderToStaticMarkup(<i className=" fa fa-map-marker-alt fa-3x" />);
  // const customMarker = divIcon({html: iconMarkup});

  return (
    <div className='map'>
      <div style={{zIndex: 9999999999999, color: '#f0f0f0', position: 'absolute', top: '14px', right: '10px', fontSize: '22px'}} onClick={() => props.showMap(false)}> x </div>
      <MapContainer center={props.center} zoom={21} scrollWheelZoom={false} style={{height: window.innerHeight / 2.5, width: window.innerWidth - 4, marginTop: '15px', marginBottom: '-80px'}}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
        />
        { props.posts.map(post =>
            <Marker eventHandlers={{mouseover: (e) => e.target.zIndex = 999999999, mouseout: (e) => e.target.zIndex = 9}} animate={true}
                    icon={divIcon({html: renderToStaticMarkup(<img alt='' src={`https:${post.image_url}`}
                    style={{width: '34px', height: '40px', borderRadius: '6px'}} />)})} position={[post.location[1], post.location[0]]}>
              <Popup>
                <div onClick={() => {props.setPost(post); props.showMap(false)}}><img alt='' src={`https:${post.image_url}`} style={{height: '84px'}} /></div>
              </Popup>
            </Marker>
          )
         }
      </MapContainer>
    </div>
  );
}

export default Map;
