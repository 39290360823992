import { useState, useEffect, useRef } from 'react';
import MiniPost from './MiniPost';

const History = (props) => {
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [messageString, setMessageString] = useState('');
  const [fetching, setFetching] = useState(false);
  const listInnerRef = useRef();

  const sitems = [ 
    'memories...',
    'drama...',
    'nostalgia...',
    'history...',
    'time capsules...',
    'alien dna samples...',
    'dad jokes...',
    'jelly beans...',
    'zombie apocalypse...'
  ];  

  const fetchHistory = async (search) => {
    if (fetching) {
      return;
    }
    setFetching(true);
    await setMessageString(`Fetching ${sitems[Math.floor(Math.random()*sitems.length)]}`);
    let requestData = {
      uri: '/posts?page=' + page + '&session=' + props.session,
      method: 'GET'
    };
    let getUri = requestData.uri;
    const data = await fetch(getUri);
    const d = await data.json();
    if (d['posts'].length > 0) {
      if ((page > 1 && posts) || page === 1) {
        let newPosts = [];
        if (posts) {
          newPosts = Object.assign([], posts);
        }
        newPosts = newPosts.concat(d['posts']);
        setPosts(newPosts);
        setFetching(false);
        setPage(page + 1);
        setMessageString('');
      }
    } else {
      setMessageString('');
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    const listInnerElement = listInnerRef.current;

    if (listInnerElement) {
      console.log('  Adding scroll listener');
      listInnerElement.removeEventListener('scroll', onScroll);
      listInnerElement.addEventListener("scroll", onScroll);
    } else {
      console.log("  GOT NO REF");
    }
  }, []);

  const onScroll = () => {
    console.log('Onscroll called...');
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom) {
        fetchHistory();
      }
    }
  };

  return (
    <div className="history" ref={listInnerRef}>
      <span style={{fontWeight: 'bold'}}>History</span>
      <br />
      <div>{messageString}</div>
      {
        (posts && posts.length > 0)
           ?  posts.map(p => <MiniPost session={props.session} post={p} />)
           : <div>No posts! Why not go and create one? Tap on the camera icon down there at the bottom!</div>
      }
    </div>
  );
}

export default History;
