import React, { useCallback, useRef, useState } from "react";
import flipButton from '../images/flip-button.png';
import Webcam from "react-webcam";
import CancelIcon from '@mui/icons-material/Cancel';
import fullCamButton from '../images/camera-button.png';
import checkButton from '../images/kilroy-button-check.png';
import CircularProgress from '@mui/material/CircularProgress';

function useLongPress(callback : () => any, ms = 500) {
  // used to persist the timer state
  // non zero values means the value has never been fired before
  const timerRef = useRef(0);

  // clear timed callback
  const endTimer = () => {
    clearTimeout(timerRef.current || 0); 
    timerRef.current = 0;
  };  

  const onStartLongPress = useCallback((e) => {
    // stop any previously set timers
    endTimer();
    // set new timeout
    timerRef.current = window.setTimeout(() => {
      callback();
      endTimer();
    }, ms);
  }, [callback, ms]);

  // determine to end timer early and invoke the callback or do nothing
  const onEndLongPress = useCallback(() => {
    // run the callback fn the timer hasn't gone off yet (non zero)
    if (timerRef.current) {
      endTimer();
      callback(timerRef.current);
    }   
  }, [callback]);
  return [onStartLongPress, onEndLongPress, endTimer];
}

export default function WebcamVideo({setMainMessageString, coords, ...props}) {
  const [cameraDirection, setCameraDirection] = useState('environment');
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVideo, setPreviewVideo] = useState();
  const [previewVideoBlob, setPreviewVideoBlob] = useState();
  const [fetching, setFetching] = useState(false);
  const [hashtags, setHashtags] = useState('');
  const [messageString, setMessageString] = useState('');

  const handlePreview = () => {
    setCapturing(false);
    setPreviewImage(webcamRef?.current.getScreenshot());
  };

  const isDev = () => {
    return window.location.href.indexOf('localhost') !== -1;
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    let vidmimetype = 'video/mp4';
    if (isDev()) {
      vidmimetype = 'video/webm';
    }
    const mimetype = previewVideo ? vidmimetype : mimeString;
    return new Blob([ia], { type: mimetype })
  }

  const handleTagUpdates = (d) => {
    setHashtags(d.target.value);
  };

  const handleCapture = () => {
    setFetching(true);
    setMessageString('Saving...');
    // const imageSrc = avatarEditorRef?.current?.getImageScaledToCanvas().toDataURL();
    const dataSrc = previewImage || previewVideo;
    const data = {
      post: {
        hashtags: hashtags, 
        loc: [coords.latitude, coords.longitude],
        cookie: props.kilroyCookie
      },    
    };    
    if (previewImage) {
      const media = DataURIToBlob(dataSrc);
      data.post['image_data'] = media;
    } else if (previewVideo) {
      data.post['video_data'] = previewVideoBlob;
    }
    let reqData = {
      uri: '/posts',
      method: 'POST'
    };    
    try { 
      let fd = new FormData();
      if (data['post']['hashtags']) {
        fd.append('post[tags]', data['post']['hashtags']);
      } 
      if (previewImage && data['post']['image_data']) {
        fd.append('post[user_image]', data['post']['image_data'], 'postImage.png');
      }
      if (previewVideo && data['post']['video_data']) {
        let vidname = 'postVideo.mp4';
        if (isDev()) {
          vidname = 'postVideo.webm';
        }
        fd.append('post[user_video]', data['post']['video_data'], vidname);
      }
      if (data['post']['cookie']) {
        fd.append('post[cookie]', data['post']['cookie']);
      }
      fd.append('post[location]', data['post']['loc']);
      reqData['body'] = fd;
      fetch(reqData.uri, reqData).then(postData => {
        if (postData?.status && (postData?.status === 413 || postData?.status === '413')) {
          alert('Video too large. Please create a shorter video.');
          setFetching(false);
          setMessageString('');
          setPreviewImage(false);
          setPreviewVideo(false);
          return;
        }
        postData.json().then((dj) => {
          let updatedUser = dj['post'];
          if (updatedUser['image_url']) {
            setFetching(false);
            props.setShowCamera(false);
            setPreviewImage(false);
            setMessageString('');
            setMainMessageString('');
          }
        });
      });
    } catch(e) {
      alert('There was an error uploading your media: ' + e);
      console.log('Got an error!: ' + e);
    }
  }

  const flipCamera = () => {
    if (cameraDirection === 'user') {
      setCameraDirection('environment');
    } else {
      setCameraDirection('user');
    }
  };

  const [onStart, onEnd] = useLongPress((secs) => {
    if (secs === undefined && !capturing) {
      console.log('Start video record!')
      handleStartCaptureClick();
    }
  });

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        mediaRecorderRef?.current?.stop();
        setRecordedChunks((prev) => prev.concat(data));
        const blob = new Blob(recordedChunks.concat(data), { type: isDev() ? 'video/webm' : "video/mp4" });
        const url = URL.createObjectURL(data);
        setPreviewVideoBlob(blob);
        setPreviewVideo(url);
        setCapturing(false);
        // setRecordedChunks([]);
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    if (previewImage) {
      return;
    }
    setCapturing(true);
    setMessageString('Tap to end recording.');
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isDev() ? 'video/webm' : "video/mp4",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef?.current?.stop();
  }, [mediaRecorderRef, setCapturing]);

  return (
    <div style={{display: 'flex', position: 'absolute', width: window.innerWidth, top: '70px', marginLeft: '-1px', margin: '0px', padding: '0px', verticalAlign: 'top', flex: 1}}>
      { !previewImage && !previewVideo && 
      <Webcam
        imageSmoothing={true}
        muted={true}
        width={window.innerWidth}
        audio={true}
        mirrored={false}
        ref={webcamRef}
        videoConstraints={{facingMode: cameraDirection}}
      /> }

      { !previewVideo && <span style={{fontColor: '#f0f0f0', color: '#f0f0f0', messageString}}>{messageString}</span> }
      <div className='camera-block'>
        { (capturing && !previewImage) && <div className='recording'>Recording...</div> }
        { (!previewImage && !previewVideo) && <CancelIcon sx={{paddingBottom: '5px', width: '58px', height: '58px'}}
                                                          onClick={() => {setPreviewImage(undefined); props.setShowCamera(false)}} /> }
        { !previewImage && !fetching && !previewVideo
          && <svg onClick={() => capturing ? handleStopCaptureClick() : handlePreview()} width='140' xmlns="http://www.w3.org/2000/svg">
               <image alt='' style={{width: '140px'}} href={fullCamButton} onTouchStart={() => onStart()} />
             </svg> }
        { previewVideo && <video autoPlay playsInline controls loop='true'><source src={previewVideo} type={ isDev() ? 'video/webm' : "video/mp4" } /></video> }
        { previewImage && <div><img alt='' src={previewImage} /></div> }
        { (!previewImage && !previewVideo) ? <img alt='' src={flipButton} onClick={flipCamera} /> : <></> }
        { (previewImage || previewVideo) && <div style={{marginTop: '10px', display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
              <input placeholder='Comment' type='text' onChange={(d) => handleTagUpdates(d)}
                     className='post-text-input'
              />
              <div style={{display: 'flex', flexDirection: 'row', height: '124px'}}>
                <div className='cancel-icon-preview'>
                   <CancelIcon sx={{width: '58px', height: '58px'}} onClick={() => {setPreviewImage(undefined); props.setShowCamera(false)}} />
                </div>
                { fetching ? <CircularProgress sx={{marginTop: '40px', display: 'flex', left: '44%', transform: 'translate(-50%, 0)', position: 'fixed'}}
                                               size={48} color='inherit' />
                           : <img alt='' className='cambutton' src={checkButton} onClick={handleCapture} /> }
              </div>
           </div>
         }
      </div>
    </div>
  );
}
