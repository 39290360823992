import 'leaflet/dist/leaflet.css';
import './App.css';
import { getDistance } from 'geolib';
import React, { useState, useEffect, useRef } from 'react';
import { useGeolocated } from 'react-geolocated';
import WebcamVideo from './components/WebcamVideo';
import Post from './components/Post';
import DocumentMeta from 'react-document-meta';
import Cookies from 'universal-cookie';
// import AvatarEditor from 'react-avatar-editor';
import Header from './components/Header';
import Map from './components/Map';
import History from './components/History';
import logo from './images/kilroy-name-logo-white.png';
import fullCamButton from './images/camera-button.png';
import queryString from 'query-string';
import { Client } from '@stomp/stompjs';

window.addEventListener("load",function() {
  // Set a timeout...
  setTimeout(function() {
    // Hide the address bar!
    window.scrollTo(0, 1);
  }, 0);
});

if (window.location.href.indexOf('http:') !== -1) {
  // Redirect to TLS..
  const tlsUrl = window.location.href.replace('http:', 'https:');
  window.location.href = tlsUrl;
}

function App() {
  const isDev = () => {
    if (window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('127.0.0') !== -1) {
      return true;
    }
    return false;
  };
  const params = queryString.parse(window.location.search);
  const coasterId = params.id;
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
        [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
        onWindowResize = () => {
          clearTimeout(window.resizeLag)
          window.resizeLag = setTimeout(() => {
            delete window.resizeLag
            // setOrientation(isLandscape() ? 'landscape' : 'portrait')
            setOrientation('portrait');
          }, 200)
        }

  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ),[]);

  const cookies = new Cookies();
  const sitems = [
    'memories...',
    'drama...',
    'nostalgia...',
    'history...',
    'time capsules...',
    'alien dna samples...',
    'dad jokes...',
    'jelly beans...',
    'zombie apocalypse...'
  ];
  const [searchString, setSearchString] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [showTip, setShowTip] = useState(false);
  const [kilroyCookie, setKilroyCookie] = useState(cookies.get('kilroycookie'));
  const [showCamera, setShowCamera] = useState(false);
  const [showSplash, setShowSplash] = useState(true);
  const [messageString, setMessageString] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showMy, setShowMy] = useState(false);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [debug, setDebug] = useState(false);
  const [oldCoords, setOldCoords] = useState();
  const [stompClient, setStompClient] = useState();
  const [subscriptions, setSubscriptions] = useState([]);
  const listInnerRef = useRef();

  useEffect(() => {
    if (stompClient && stompClient.connected && posts && posts.length > 0) {
      for (let i = 0; i < posts.length; i++) {
        console.log('Stomp client exists.  Binding to topic: ' + posts[i]._id.$oid);
        if (subscriptions.indexOf(posts[i]._id.$oid) === -1) {
          stompClient.subscribe(`/topic/${posts[i]._id.$oid}`, message => {
            const messageObject = JSON.parse(message.body);
            let mtype = undefined;
            if (messageObject['like']) {
              mtype = 'like';
            } else if (messageObject['comment']) {
              mtype = 'comment';
            }
            if (mtype) {
              // We has a like. Update objects.
              let newPosts = [...posts];
              let foundTarget = false;
              for (let p = 0; p < posts.length; p++) {
                if (newPosts[p]._id.$oid === messageObject[mtype].post_id.$oid) {
                  newPosts[p][mtype + 's'].push(messageObject[mtype]);
                  foundTarget = true;
                  break;
                }
              }
              if (foundTarget) {
                setPosts(newPosts);
              }
            }
          });
          let newSubs = [...subscriptions];
          newSubs.push(posts[i]._id.$oid);
          setSubscriptions(newSubs);
        } else {
          console.log('  Already bound to topic: ' + posts[i]._id.$oid);
        }
      }
    }
  }, [posts]);

  const { coords, isGeolocationAvailable } = useGeolocated({
          positionOptions: {
            enableHighAccuracy: false,
            maximumAge: 120000
          },
          watchLocationPermissionChange: true,
          userDecisionTimeout: 8000,
          watchPosition: true,
          onError: (err) => { setShowSplash(false); setMessageString('Unable to fetch location. Try to refresh and accept the location request, '
                                    + 'and verify that your device has location services enabled for this browser.') },
          onSuccess: (data) => console.log("Got location data (onSuccess): " + data.latitude)
  });

  const doHome = () => {
    setPage(1);
    setShowMap(false);
    setShowCamera(false);
    setShowHistory(false);
    setShowMy(false);
    setShowSearch(false);
    setSearchString(undefined);
    setThumbnail();
  }

  const fetchPosts = async (search, isInit) => {
    if (fetching) {
      return;
    }
    if (!coords) {
      return;
    }
    setFetching(true);
    await setMessageString(`Fetching ${sitems[Math.floor(Math.random()*sitems.length)]}`);
    let requestData = {
      uri: '/posts',
      method: 'GET'
    };
    let getUri = requestData.uri + '?loc=' + coords.latitude + ',' + coords.longitude;
    if (showMy) {
      getUri += '&session=' + kilroyCookie;
    }
    if (search && search !== '') {
      getUri += '&filter=' + search;
    } else {
      if (isInit) {
        getUri += '&page=1';
      } else {
        getUri += '&page=' + page;
      }
    }
    if (kilroyCookie) {
      getUri += '&s=' + kilroyCookie;
    }
    if (coasterId) {
      getUri += '&id=' + coasterId;
    }
    const data = await fetch(getUri);
    const d = await data.json();
    if (searchString && searchString !== '') {
      setPosts(d['posts']);
      if (d['posts'].length === 0) {
        setMessageString('No matching results found.');
      } else {
        setMessageString('');
      }
      setPage(1);
    } else {
      if (!d['has_posted'] || d['has_posted'] === 0 || d['has_posted'] === '0') {
        setShowTip(true);
      }
      if (d['posts'].length > 0) {
        if ((page > 1 && posts) || page === 1) {
          let newPosts = [];
          if (posts && !isInit) {
            newPosts = Object.assign([], posts);
          }
          let newPostsFiltered = [];
          for (let i = 0; i < d['posts'].length; i++) {
            if (posts.filter(ppp => d['posts'][i]._id.$oid === ppp._id.$oid).length === 0) {
              newPostsFiltered.push(d['posts'][i]);
            }
          }
          newPosts = newPosts.concat(newPostsFiltered);
          setPosts(newPosts);
          setFetching(false);
          setPage(page + 1);
          setMessageString('');
        }
      } else if (d['posts'].length === 0 && page === 1) {
        await setMessageString("Looks like you're the first one in this location! Add a kilroy to the timeline!");
      } else {
        setMessageString('');
      }
    }
    setShowSplash(false);
    setFetching(false);
  };

  useEffect(() => {
    if (window.location.href.indexOf('debug=t') !== -1) {
      setDebug(true);
    }
    const initMe = async () => {
      if (oldCoords?.latitude && !showMy) {
        const distance = getDistance({latitude: oldCoords.latitude, longitude: oldCoords.longitude},
                                     {latitude: coords.latitude, longitude: coords.longitude});
        if (distance > 30) {
          if (debug) {
            alert('Updating location because distance from last location is: ' + distance);
          }
          setPage(1);
          await fetchPosts(undefined, true);
        }
      } else {
        await fetchPosts(undefined, true);
      }
      await setOldCoords(coords);
    }
    initMe();
  }, [coords, searchString]);

  useEffect(() => {
    const initMe = async () => {
      await fetchPosts(undefined, true);
    };
    initMe();
  }, [showMy]);

  useEffect(() => {
    const listInnerElement = listInnerRef.current;
    if (listInnerElement) {
      listInnerElement.removeEventListener('scroll', onScroll);
      listInnerElement.addEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    if (!kilroyCookie) {
      setKilroyCookie(cookies.get('kilroycookie'));
      if (!kilroyCookie) {
        const value = Math.random(100.999);
        cookies.set('kilroycookie', value, {path: '/', expires: new Date(Date.now() + 86400 * 1000 * 364)});
        setKilroyCookie(value);
      }
    }
    const proto = isDev() ? 'ws' : 'wss';
    if (!stompClient && kilroyCookie) {
      let sClient = new Client();
      sClient.configure({
        // brokerURL: 'wss://b-20754911-1718-4778-b20c-cb7eac291fb1-1.mq.us-east-1.amazonaws.com:61619',
        brokerURL: proto + '://' + window.location.host + '/ws',
        connectHeaders: {
          login: 'loudlabs',
          passcode: 'loudp@55loudp@55',
        },
        onConnect: () => {
          setStompClient(sClient);
          if (kilroyCookie) {
            sClient.subscribe(`/topic/${kilroyCookie}`, post => {
              const postObject = JSON.parse(post.body);
              if (posts.map(post => post._id.$oid).indexOf(postObject.post._id.$oid) === -1) {
                let newPosts = [...posts];
                newPosts.unshift(postObject.post);
                setPage(1);
                setPosts(newPosts);
              }
            });
          }
        },
        debug: (str) => {
          console.log(new Date(), str);
        }
      });
      sClient.activate();
      setStompClient(sClient);
    }
  }, [kilroyCookie]);

  const onScroll = () => {
    setShowTip(false);
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;
      if (isNearBottom) {
        fetchPosts();
      }
    }
  };

  const metaData = {
    title: 'Kilroy',
    meta: {
      name: 'viewport',
      content: 'width=device-width',
      initialScale: '1.00',
      maximumScale: '1.0',
      userScalable: 'no',
      'initial-scale': 1.00,
      'maximum-scale': 1.0,
      'user-scalable': 'no',
      'apple-mobile-web-app-capable': 'yes'
    }
  };

  const setPostsWrapper = (posts) => {
    if (posts.length > 0) {
      setMessageString(undefined);
    }
    setPosts(posts);
  }

  const showCameraWrapper = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowTip(false);
    setShowCamera(true);
  };

  const handleThumbnailClick = (pid) => {
    let requestData = {
      uri: `/posts/${pid}`,
      method: 'GET'
    };
    fetch(requestData.uri).then(data => {
      data.json().then((d) => {
        setThumbnail(d['post']);
      });
    });
  };

  const handleSearchUpdate = async (d) => {
    await setSearchString(d.target.value);
  };

  const performSearch = async () => {
    await fetchPosts(searchString);
  };

  return (
    <DocumentMeta {...metaData}>
      <div>{orientation}</div>
      { showSplash && <div className='splash'><center><img alt='' style={{width: '180px'}} src={logo} /></center></div> }
      <div className="App">
        <Header showCamera={showCamera} setShowCamera={setShowCamera}
                showMy={showMy} setShowMy={setShowMy}
                showHistory={showHistory} setShowHistory={setShowHistory}
                showMap={showMap} setShowMap={setShowMap}
                showSearch={showSearch} setShowSearch={setShowSearch}
                coords={coords}
                doHome={doHome}
        />

        { ( !showCamera && showSearch ) ?
           <>
            <div className='search'>
              <form onSubmit={(e) => {e.preventDefault(); performSearch(); return false}}>
                <input style={{display: 'none'}} type='submit' onSubmit={(e) => {e.preventDefault(); performSearch(); return false}} />
                <input className='search-input' type='text' value={searchString} onChange={(d) => handleSearchUpdate(d)} />
              </form>
            </div>
          </>
        : <></> }

        { !showCamera && showMap && <Map showMap={setShowMap} setPost={(p) => setThumbnail(p)} posts={posts} center={[coords.latitude, coords.longitude]} /> }
        { showCamera
                   ?
                     <WebcamVideo setMainMessageString={setMessageString} coords={coords} kilroyCookie={kilroyCookie} posts={posts} setPosts={setPostsWrapper} setShowCamera={setShowCamera} />
                   : <div>
                     <div className='geobar'>
                       {
                          ( isGeolocationAvailable && coords )
                          ? <b>{Math.round(coords.latitude * 100000) / 100000},
                               {Math.round(coords.longitude * 100000) / 100000}
                               {kilroyCookie ? <span style={{fontSize:'12px', color: '#dddddd'}}>
                                                     {kilroyCookie.toString().substring(kilroyCookie.toString().length - 4, kilroyCookie.toString().length)}
                                               </span> : ''}
                            </b>
                          : <b>Checking geolocation support...</b>
                        }
                     </div>
                   </div>
        }
        { !showCamera &&
            <div id='kilroy' onScroll={onScroll} ref={listInnerRef}
                 style={{maxWidth: '100%', overflowX: 'hidden', height: `${window.innerHeight}px`, overflowY: 'scroll'}}>
              { showMy && <div className='timeline-spacer-medium'> </div> }

              { showMy && <div style={{height: '38px'}}><span style={{fontWeight: 'bold', fontSize: '20px'}}>My Kilroys in this location...</span></div> }
              { showHistory && <div className='timeline-spacer-top'> </div> }
              { showHistory && <History session={kilroyCookie} /> }
              { posts && posts.length > 0 && !thumbnail && !showMy && <div className={showSearch ? 'timeline-spacer-medium' : 'timeline-spacer-top'}> </div> }
              {
                (posts && posts.length > 0 && !thumbnail && !showHistory) ?
                   posts.map(p => <Post hideTip={() => setShowTip(false)} session={kilroyCookie} handleThumbnailClick={handleThumbnailClick} data={p} />)
                 : thumbnail ? <><div className='timeline-spacer-top'></div><Post hideTip={() => setShowTip(false)} cancelThumbnail={() => setThumbnail()} session={kilroyCookie} data={thumbnail} /></> : <></>
              }
              { posts && posts.length > 0 && !thumbnail && !showHistory && !showSearch && <div className='timeline-spacer-bottom'>.</div> }
              {
                 messageString && !showCamera && !fetching && <center><div className='message-div'>{messageString}</div></center>
              }
              { showTip && posts.length === 0 && <div onClick={() => setShowTip(false)} className='tip'>Looks like you're new to Kilroy! Kilroy is an anonymous social media platform, based entirely on your current location. Get started by posting a photo of the area around you. Just tap on the camera button below!</div> }
              <div className='mainbutton'>
                <img alt='' style={{width: '90px'}} src={fullCamButton} onClick={showCameraWrapper}
                />
              </div>
            </div>
        }
      </div>
    </DocumentMeta>
  );
}

export default App;
