import logo from '../images/kilroy-name-logo-white.png';
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import MapIcon from '@mui/icons-material/Map';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from 'react';

const Header = (props) => {
  const [locName, setLocName] = useState();
  const [fetching, setFetching] = useState(false);

  const getMyLocationName = async (lat, lon) => {
    if (!fetching) {
      await setFetching(true);
      const data = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
      const d = await data.json();
      await setLocName(d.name || d.address?.suburb || d.address?.road);
      await setFetching(false);
    }
  };

  useEffect(() => {
    async function doInit() {
      if (props.coords) {
        await getMyLocationName(props.coords.latitude, props.coords.longitude);
      }
    }
    doInit();
  }, [props.coords]);

  const doShowMap = () => {
    props.setShowHistory(false);
    props.setShowSearch(false);
    props.setShowMy(false);
    props.mapState ? props.setShowMap(false) : props.setShowMap(true);
  };

  const doShowHistory = () => {
    props.setShowMap(false);
    props.setShowSearch(false);
    props.setShowMy(false);
    props.setShowHistory(true);
  };

  const doShowSearch = () => {
    props.setShowMap(false);
    props.setShowHistory(false);
    props.setShowMy(false);
    props.setShowSearch(true);
  };

  const doShowMy = () => {
    props.setShowMap(false);
    props.setShowHistory(false);
    props.setShowSearch(false);
    props.setShowMy(true);
  };

  const doHome = () => {
    props.doHome();
  };

  const isHome = () => {
    return (!props.showMap && !props.showHistory && !props.showMy && !props.showSearch && !props.showCamera );
  }

  return (
     <div className='header'>
        <img onClick={() => window.location.reload()} alt='' style={{width: '110px', marginLeft: '-10px'}} src={logo} />
        { !props.showCamera &&
        <div className='buttonbar'>
          <div className='buttonwrapper'>
            <div className={isHome() ? 'fullblackcircle ' : 'blackcircle' }>
              { isHome() ? <FmdGoodRoundedIcon sx={{color: 'white'}} onClick={() => doHome()} /> : <FmdGoodRoundedIcon onClick={() => doHome()} /> }
            </div>
          </div>
          <div className='buttonwrapper'>
            <div className={(props.showMy && !props.showCamera) ? 'fullblackcircle' : 'blackcircle'}>
              { props.showMy ? <InsertPhotoIcon sx={{color: 'white'}} /> : <InsertPhotoIcon onClick={() => doShowMy()} /> }
            </div>
          </div>
          <div className='buttonwrapper'>
            <div className={(props.showHistory && !props.showCamera) ? 'fullblackcircle' : 'blackcircle'}>
              { props.showHistory ? <HistoryRoundedIcon sx={{color: 'white'}} /> : <HistoryRoundedIcon onClick={() => doShowHistory()} /> }
            </div>
          </div>
          <div className='buttonwrapper'>
            <div className={(props.showMap && !props.showCamera) ? 'fullblackcircle' : 'blackcircle'}>
              { props.showMap ? <MapIcon sx={{color: 'white'}} /> : <MapIcon onClick={() => doShowMap()} /> }
            </div>
          </div>
          <div className='buttonwrapper'>
            <div className={(props.showSearch && !props.showCamera) ? 'fullblackcircle' : 'blackcircle'}>
              { props.showSearch ? <SearchIcon sx={{color: 'white'}} /> : <SearchIcon onClick={() => doShowSearch()} /> }
            </div>
          </div>
        </div>
       }
       { props.coords && <span style={{fontWeight: 'bold', fontSize: '14px' }}><span style={{fontWeight: 'bold', fontSize: '16px'}}>{locName}</span> {Math.round(props.coords.latitude * 100000) / 100000}, {Math.round(props.coords.longitude * 100000) / 100000}</span> }
     </div>
  );
}

export default Header;
