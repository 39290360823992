import React, { useState, useEffect } from 'react';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TimeAgo from 'react-timeago'
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import CircularProgress from '@mui/material/CircularProgress';

const Post = (props) => {
  const kilroyCookie = props.session;
  const [fetching, setFetching] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [comment, setComment] = useState();
  const [comments, setComments] = useState(props.data.comments);
  const [isLiking, setIsLiking] = useState(false);

  useEffect(() => {
    setIsLiking(false);
  }, [props.data]);

  const reply = async () => {
    // Check to see if their cookie has posted a picture. If not, force it.
    // Have to post a picture to be able to reply to a picture.
    if (!kilroyCookie) {
      alert('You do not have a valid session!');
    }
    const canComment = await fetchPostByCookie();
    if (!canComment) {
      alert('You must first post a picture from this location before you can comment.');
      return;
    }
    setShowReplyInput(true);
  };

  const like = () => {
    setIsLiking(true);
    let likeData = {
      uri: '/likes',
      method: 'POST',
    }
    try {
      let fd = new FormData();
      fd.append('like[post_id]', props.data._id.$oid);
      fd.append('like[cookie]', kilroyCookie);
      likeData['body'] = fd;
      fetch(likeData.uri, likeData).then(cd => {
        cd.json().then((cj) => {
          // Now setLikes after pushing this one into the existing ones..
          // const oldLikes = Object.assign([], likes);
          // const newLikes = oldLikes.concat(cj['like']);
          // setLikes(newLikes);
          // setHasLiked(true);
          // This is a nooop here, because we get the likes off of the bus.
          console.log('Posted like...');
        });
      });
    } catch(e) {
      console.log('Error posting like: ' + e);
    }
  };

  const cancelReply = () => {
    setShowReplyInput(false);
  };

  const fetchPostByCookie = async () => {
    if (fetching) {
      console.log('Already fetching. Like wedge.');
      return;
    }
    if (!props.data.location) {
      console.log("No location!!!");
      return;
    }
    setFetching(true);
    let requestData = {
      uri: '/posts',
      method: 'GET'
    };
    const data = await fetch(requestData.uri + '?loc=' + props.data.location[1]
          + ',' + props.data.location[0] + '&cookie=' + kilroyCookie);
    const jd = await data.json();
    if (jd['posts'] && jd['posts'].length > 0) {
      console.log('Comment OK');
      // setCommenterPost(jd['posts'][0]);
      return true;
    } else {
      console.log('Comment NOT OK');
      return false;
    }
  };

  const submitComment = () => {
    let commentData = {
      uri: '/comments',
      method: 'POST',
    }
    try {
      let fd = new FormData();
      fd.append('comment[body]', comment);
      fd.append('comment[cookie]', kilroyCookie);
      fd.append('comment[post_id]', props.data._id.$oid);
      commentData['body'] = fd;
      fetch(commentData.uri, commentData).then(cd => {
        cd.json().then((cj) => {
          // Now setComments after pushing this one into the existing ones..
          const oldComments = Object.assign([], comments);
          const newComments = oldComments.concat(cj['comment']);
          setComments(newComments);
          setShowReplyInput(false);
        });
      });
    } catch(e) {
      console.log('Error posting comment: ' + e);
    }
  };

  const handleCommentInput = (d) => {
    setComment(d.target.value);
  };

  const isVidUrl = (filestr) => {
    if (filestr.indexOf('mp4') !== -1) {
      return true;
    } else if (filestr.indexOf('webm') !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const isDev = () => {
    return window.location.href.indexOf('localhost') !== -1;
  };

  return (
    <div className='post'>
      <div className='post-main'>
        { (props.data.image_url && isVidUrl(props.data.image_url)) ? <video muted playsInline autoplay controls loop='true' autoPlay='autoplay' onClick={() => { props.hideTip(); props.cancelThumbnail && props.cancelThumbnail()}} alt='' className='post-image'><source src={props.data.image_url} type={isDev() ? 'video/webm' : 'video/mp4' } /></video> : <></> }
        { (props.data.image_url && !isVidUrl(props.data.image_url)) ? <img onClick={() => { props.hideTip(); props.cancelThumbnail && props.cancelThumbnail()}} alt='' src={props.data.image_url} className='post-image' /> : <></> }
        <div className='post-bottom'>
          <div className='post-bottom-left'>
            <div className='whitecircle' onClick={() => like()}>
              { (props.data.likes?.filter(pl => pl.cookie === kilroyCookie.toString()).length > 0)
                  ? <FavoriteRoundedIcon />
                  : isLiking ? <CircularProgress sw={{width: '30px', height: '30px'}} /> :  <FavoriteBorderRoundedIcon /> }
              <div style={{marginLeft: '18px', fontSize: '14px', paddingTop: '4px'}}> {props.data.likes.length}</div>
            </div>
            <div className='whitecircle' style={{marginLeft: '88px', marginTop: '-44px'}} onClick={() => reply()}>
               <AddCommentIcon />
            </div>
          </div>
          <div className='post-bottom-right'>
            <div className='post-time-ago'>
              <TimeAgo date={props.data.created_at} />
            </div>
            { props.data.tags ? <span style={{fontSize: '16px', fontWeight: 'bold'}}>{props.data.tags}</span> : <></> }
          </div>
        </div>
      </div>

      <div style={{width: '95%', marginLeft: '20px', marginRight: '20px', padding: '10px'}}>
         {
            (comments && comments.length > 0) ?
               comments.map(c => <div style={{marginTop: '8px', textAlign: 'left'}}>
                                   <img alt='' onClick={() => props.handleThumbnailClick && props.handleThumbnailClick(c.tid)} style={{width: '34px'}} src={c.thumbnail} />
                                   <span style={{fontSize: '14px', color: '#202020'}}>{c.body}</span>
                                   <span style={{fontSize: '12px', color: '#242424'}}><TimeAgo date={c.created_at} /></span>
                                 </div>)
               : <></>
         }
       </div>
       { showReplyInput ?
           <div>
              <form onSubmit={(e) => {e.preventDefault(); submitComment(); return false;}}>
                <input className='comment-input' type='text'
                       onSubmit={(e) => {e.preventDefault(); submitComment(); return false;}}
                       onChange={(d) => handleCommentInput(d)} />
                &nbsp; <div className='llbutton' onClick={() => cancelReply()}>Cancel</div>
              </form>
           </div>
           :
           <></>
        }
    </div>
  );
}

export default Post;
